import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import "./BlogCard.css"

const BlogCard = ({ data }) => {
  const { title, date, featuredimage, description } = data.frontmatter
  const { slug } = data.fields
  return (
    <div className="blog-card">
      <Link to={slug}>
        <h2>{title}</h2>
        <span>{date}</span>
        <Img fluid={featuredimage.childImageSharp.fluid} />
      </Link>
      <p className="post-description">{description}</p>
      <Link className="more-btn" to={slug}>
        więcej...
      </Link>
    </div>
  )
}

export default BlogCard
